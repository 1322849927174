/* eslint-disable import/prefer-default-export */
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { CookiesProvider } from 'react-cookie';
import { client } from './client';

// eslint-disable-next-line react/display-name,react/prop-types
export const wrapRootElement = ({ element }) => (
  <CookiesProvider>
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        {element}
      </ApolloHooksProvider>
    </ApolloProvider>
  </CookiesProvider>
);

// export default wrapRootElement;
